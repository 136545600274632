<template>
    <footer>
        <div class="alinear">
            <div class="logo ">
               <a href="https://www.explora.cl/" target="_blank" rel="noopener noreferrer"><img  class="img-responsive" src="/assets/img/logo-min-explora.svg" alt=""></a> 
            </div>
            <div class="">
                <ul>
                    <li><router-link to="/congresos-anteriores">Congreso anterior</router-link></li>
                    <li><router-link to="/politicas">Política de privacidad</router-link></li>
                </ul>
            </div>
            <div class="">
               <a href="https://www.instagram.com/explora_cl/" target="_blank" rel="noopener noreferrer">
               
                    <!-- Generator: Adobe Illustrator 27.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                    <svg class="iconos" version="1.1" id="insta" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
                    <g>
                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9
                            s12.5,27.9,27.9,27.9H40.4z"/>
                        <path class="st0" d="M47.5,55h-15c-4.2,0-7.5-3.4-7.5-7.5v-15c0-4.2,3.4-7.5,7.5-7.5h15c4.2,0,7.5,3.4,7.5,7.5v15
                            C55,51.6,51.6,55,47.5,55z"/>
                        <circle class="st0" cx="40" cy="40" r="6"/>
                        <circle class="st0" cx="48.2" cy="30.8" r="0.8"/>
                    </g>
                    </svg>
                </a>

              
               <a href="https://twitter.com/explora_cl" target="_blank" rel="noopener noreferrer">
                
                    <!-- Generator: Adobe Illustrator 27.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                    <svg  class="iconos" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve">
                    
                    <g>
                        <path class="st0" d="M40.4,67.9c15.4,0,27.9-12.5,27.9-27.9S55.8,12.1,40.4,12.1h-0.8c-15.4,0-27.9,12.5-27.9,27.9
                            s12.5,27.9,27.9,27.9H40.4z"/>
                        <g>
                            <path class="st0" d="M25,51c3.8,0.4,7.2-0.6,10.2-2.9c-0.3,0-0.6,0-0.8-0.1c-1.5-0.2-2.7-0.8-3.8-1.9c-0.8-0.8-1.4-1.7-1.8-2.8
                                c0-0.1,0-0.2,0.1-0.1c0.4,0.1,0.9,0.1,1.4,0.1c0.5,0,1-0.1,1.5-0.2c-0.1,0-0.2-0.1-0.4-0.1c-1-0.3-2-0.8-2.8-1.5
                                c-1.3-1.2-2.1-2.6-2.3-4.3c0-0.3-0.1-0.6-0.1-0.8c0-0.1,0-0.2,0.1-0.1c0.7,0.4,1.5,0.6,2.2,0.7c0.2,0,0.5,0.1,0.7,0.1
                                c0-0.1,0-0.1-0.1-0.1c-1.7-1.2-2.7-2.9-2.9-4.9c-0.2-1.5,0.1-2.9,0.8-4.2c0.1-0.1,0.1-0.1,0.2,0c1.6,1.9,3.4,3.4,5.5,4.6
                                c2,1.1,4.2,1.9,6.5,2.3c0.7,0.1,1.4,0.2,2.1,0.2c0.2,0,0.2,0,0.2-0.2c-0.4-2.2,0.1-4.1,1.5-5.8c0.9-1.2,2.2-1.9,3.6-2.3
                                c0.6-0.2,1.3-0.3,1.9-0.2c1.7,0.1,3.2,0.6,4.4,1.7c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.1,0.1,0.2,0.1c1.5-0.3,2.9-0.8,4.2-1.6
                                c0,0,0.1,0,0.1,0c-0.5,1.6-1.5,2.9-3,3.8c0.2,0.1,1.2-0.2,2-0.4c0.6-0.2,1.3-0.4,1.9-0.7c0,0,0,0.1,0,0.1
                                c-0.4,0.6-0.8,1.1-1.3,1.7c-0.6,0.7-1.3,1.2-2,1.8c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.3,0,0.6,0,1c0,1.4-0.2,2.9-0.5,4.3
                                c-0.4,1.7-1,3.2-1.7,4.8c-0.8,1.5-1.7,2.9-2.8,4.2c-1.3,1.5-2.8,2.8-4.5,3.8c-2,1.2-4.2,2-6.6,2.4c-1,0.2-2,0.3-3.1,0.3
                                c-2.1,0.1-4.1-0.2-6.1-0.7c-1.3-0.4-2.5-0.9-3.7-1.5c-0.5-0.2-0.9-0.5-1.4-0.8C25.1,51.1,25,51.1,25,51z"/>
                        </g>
                    </g>
                    </svg>

            
                </a>
                
            </div>    
         </div>
    </footer>
</template>

<script>
export default {
    name: 'footerLogo'
}
</script>

<style lang="scss" scoped>
footer{
    position: bottom;
    align-items: center;
    bottom:13px;
    width: auto;
    height: auto;
    padding:80px 0px 30px 20px;
    //background-color: var(--color1);
    @media(max-width:1100px){
        position: relative;
        align-items: center;
        width: auto;
        margin: auto;
    }
}
.alinear{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 1em;
    @media(max-width:1023px){
        display: grid;
        grid-template-columns: 100%;
        justify-items: center;
        gap: 1em;
    }
    
}
.logo{
    bottom:13px;
    width: 300px;
    margin: 10px;
    padding: 10px;

}

ul{
    font-size: 16px;
    font-weight: 600;
    margin-left: 30px;
    list-style-image: url('/assets/img/chevron-right-solid.svg');
    @media(max-width:1023px) {
        margin: 0;
        
    }
}

a{
    color:#000000;
}


.iconos{
    align-items: center;
    width: 70x;
    height: 70px;
    margin-left: 10px;


}


</style>