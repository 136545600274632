<template>
    <header>
        <div class="logo" v-if="mostrar">
            <a href="/" v-if="widthScreen()"><img src="/assets/img/logo_texto.svg" alt=""></a>
            <a href="/" v-else><img src="/assets/img/logo_texto-mobile.svg" alt=""></a>
        </div>
        <div class="logo" v-else>

        </div>
        <div class="boxMn">
            <nav v-if="!pauta" class="" >
                <ul class="menu">
                    <li><router-link to="/">INICIO</router-link></li>
                    <li><router-link to="/anfiteatro">ANFITEATRO</router-link></li>
                    <li><router-link to="/feria/1">FERIA DE PROYECTOS</router-link>
                        <ul class="submenu">
                            <li><router-link to="/feria/1" @click="cambiarRegion(1)">Arica y Parinacota</router-link></li>
                            <li><router-link to="/feria/2" @click="cambiarRegion(2)">Tarapacá</router-link></li>
                            <li><router-link to="/feria/3" @click="cambiarRegion(3)">Antofagasta</router-link></li>
                            <li><router-link to="/feria/4" @click="cambiarRegion(4)">Atacama</router-link></li>
                            <li><router-link to="/feria/5" @click="cambiarRegion(5)">Coquimbo</router-link></li>
                            <li><router-link to="/feria/6" @click="cambiarRegion(6)">Valparaíso</router-link></li>
                            <li><router-link to="/feria/7" @click="cambiarRegion(7)">Metropolitana</router-link></li>
                            <li><router-link to="/feria/8" @click="cambiarRegion(8)">O'Higgins</router-link></li>
                            <li><router-link to="/feria/9" @click="cambiarRegion(9)">Maule</router-link></li>
                            <li><router-link to="/feria/10" @click="cambiarRegion(10)">Ñuble</router-link></li>
                            <li><router-link to="/feria/11" @click="cambiarRegion(11)">Biobío</router-link></li>
                            <li><router-link to="/feria/12" @click="cambiarRegion(12)">La Araucanía</router-link></li>
                            <li><router-link to="/feria/13" @click="cambiarRegion(13)">Los Rios</router-link></li>
                            <li><router-link to="/feria/14" @click="cambiarRegion(14)">Los Lagos</router-link></li>
                            <li><router-link to="/feria/15" @click="cambiarRegion(15)">Aysén</router-link></li>
                            <li><router-link to="/feria/16" @click="cambiarRegion(16)">Magallanes y Antártica Chilena</router-link></li>
                        </ul>
                    
                    </li>
                         
                    <li><router-link to="/informaciones">INFORMACIONES</router-link></li>
                    <li><router-link to="/explora">PROGRAMA EXPLORA</router-link></li>
                </ul>
            </nav>
            <form class="buscarPr" @submit.prevent="buscarProyecto()" v-if="pauta">
                <nav><router-link to="/evaluaciones"><i class="fas fa-chevron-left"></i> Sala de Evaluaciones</router-link></nav>
                <div class="boxItem">
                    <label for="">Buscar Proyecto <i class="fas fa-chevron-right"></i></label>
                    <div class="formItem">
                        <input type="text" name="" id="" v-model="form.buscarPr" placeholder="Código del Proyecto">
                        <button><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </form>
            <div class="userBox" v-if="this.loged">
                {{this.session.nombre}} {{this.session.apellidos}}
                <button class="userIco" @click="dropDownMn()">
                    <i class="fas" :class="icoMnUser"></i>
                </button>
                <ul class="dropDownMn">
                    <li>
                        <span>{{this.session.nombre}} {{this.session.apellidos}}</span>
                    </li>
                    <li>
                        <button class="btn" @click="this.logOut()">Cerrar Sesión</button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="mnBruger">
            <button class="btn" @click="toggleMn()"><i class="fas" :class="icoMnMain"></i></button>
        </div>
    </header>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
    name: 'mnSup',
    props:['pauta', 'mostrar'],
    //props:['mostrar'],
    data(){
        return{
            icoMnUser:'fa-user',
            icoMnMain:'fa-bars',
            form:{
                buscarPr:'',
                select:[]
            },
        }
    },
    mounted(){
        /* document.addEventListener('click', this.dropDownMn('rm')); */
    },
    methods:{
        ...mapMutations(['logOut']),
        toggleMn(){
            let mn = document.querySelector('.boxMn')
            if(mn.classList.contains('active')){
                mn.classList.remove('active')
                this.icoMnMain = 'fa-bars'
            }else{
                mn.classList.add('active')
                this.icoMnMain = 'fa-times'
            }

        },
        dropDownMn(){
            let mn = document.querySelector('ul.dropDownMn');

            if(mn.classList.contains('show')){
                mn.classList.remove('show')
                this.icoMnUser = 'fa-user'
            }else{
                mn.classList.add('show')
                this.icoMnUser = 'fa-chevron-up'
            }
        },
        buscarProyecto(){
            this.$emit('buscar', this.form.buscarPr);
        },
        widthScreen(){
            if(window.innerWidth>1100){
                return true
            }else{
                return false
            }
        },
        cambiarRegion(r){
                if(r>0 && r<17){
                window.location.href = `/feria/${r}`;
                }
            }
    },
    computed:{
        ...mapState(['apiURL', 'loged', 'session', 'fHoy']),
    }
}
</script>

<style lang="scss" scoped>
header{
    background-color: var(--color1);
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px;
    .logo{
        width: 400px;
        img{
            width: 100%;
            height: auto;
        }
        @media(max-width:1100px){
            width: 110px;
        }
    }
    .mnBruger{
        display:none !important;
        button.btn{
            padding-top: 10px;
            font-size: 1.1em;
            border-radius: 50% !important;
            height: 50px;
            width: 50px;
            background: #000 !important;
            color: #fff !important;
        }
        @media(max-width:1100px){
            display:block !important;
        }
    }
    .boxMn{
        height: 60px;
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: 0.3s ease-out;
        @media(max-width:1100px){
            max-height: 0px;
            top: 123px;
            display:flex;
            position: absolute;
            top: 123px;
            left: 0px;
            z-index: 500;
            flex-direction: column;
            background-color: var(--color1) !important;
            padding: 0px 20px ;
            width: 100%;
            overflow: hidden;
        }
        &.active{
            max-height: 2000px;
            padding: 50px 20px;
            height: auto;
        }
    }
    nav{
        align-items: flex-end;
        .menu{
            display: flex;
            gap:10px;
            margin-bottom: 0 ;
            transition: 5s ease-in-out;
            @media(max-width:1100px){
                flex-direction: column;
                margin-bottom:0;
            }
            li{
                list-style: none;
                @media(max-width:1100px){
                    padding: 10px;
                }
                a{
                    color:#000 !important;
                    text-decoration: none !important;
                    padding:5px 8px;
                    &:hover{
                        border-bottom:3px solid var(--color2) !important;
                        text-decoration: none !important;
                    }
                    &.navActive{
                        border-bottom:3px solid var(--color2) !important;
                        text-decoration: none !important;
                    }
                    &.router-link-exact-active{
                        border-bottom:3px solid var(--color2) !important;
                        text-decoration: none !important;
                    }
                }
            }
        }

        .submenu{
            
            display: none;
            transition: 2s ease-out;
            li{
                transition: 5s ease-in-out;
                padding: 0.2em;
                font-size: 0.8em;
                margin-left: 10px;         

                a{
                    color:#000 !important;
                    text-decoration: none !important;
                    padding:3px 5px;
                    &:hover{
                        border-bottom:2px solid var(--color2) !important;
                        text-decoration: none;
                    }
                    &.navActive{
                        border-bottom:3px solid var(--color2) !important;
                        text-decoration: none !important;
                    }
                    &.router-link-exact-active{
                        border-bottom:3px solid var(--color2) !important;
                        text-decoration: none !important;
                    }

                }
            }

        }
        .menu li:hover > ul {
                display: block;
                position: absolute;
                background-color: var(--color1) !important;
                border: 1.5px solid var(--color2) !important;
                padding: 0.2em;
                z-index: 500;
                @media(max-width:1100px)  {
                    display: block;
                    position: inherit;
                    padding: 0.2em;
                }
                
            }
    }
    form.buscarPr{
        padding: 0px;
        display: flex;
        gap: 20px;
        align-items: center;
        @media(max-width:1100px){
            display:none;
        }
        nav{
            border-right:1px solid #fff !important;
            padding-right: 10px;
            a{
                color:#fff !important;
                text-decoration: none !important;
                padding:5px 8px;
                &:hover{
                    border-bottom:3px solid var(--color2) !important;
                    text-decoration: none !important;
                }
                &.navActive{
                    border-bottom:3px solid var(--color2) !important;
                    text-decoration: none !important;
                }
                &.router-link-exact-active{
                    border-bottom:3px solid var(--color2) !important;
                    text-decoration: none !important;
                }
                i{
                    position: relative;
                    top:0px;
                }
            }
        }
        .boxItem{
            display: flex;
            gap:10px;
            align-items: center;
            margin-bottom:0px;
            label{
                font-size: 1.1rem;
                position: relative;
                top:-1px;
                i{
                    font-size: 1.3em;
                    position: relative;
                    top:4px;
                    margin:0px 10px;
                }
            }
            .formItem{
                position: relative;
                overflow: hidden;
                input{
                    border-radius: 50px;
                }
                button{
                    background: transparent;
                    cursor: pointer;
                    position:absolute;
                    top:10px;
                    right:10px;
                    color:var(--color2) !important;
                }
            }
        }
        
    }
    .userBox{
        display: flex;
        gap: 10px;
        align-items: center;
        padding-left:15px;
        margin-left:15px;
        border-left:1px solid #fff !important;
        position:relative;
        @media(max-width:1100px){
            border:1px solid #fff !important;
            justify-content: center;
            padding:10px 20px;
        }
        button.userIco{
            background: transparent;
            color:#fff !important;
            border:1px solid #fff !important;
            padding:5px;
            border-radius: 50px;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            i{
                position: relative;
                top:-1px;
            }
        }
        ul.dropDownMn{
            position:absolute;
            top:50px;
            right: 0px;
            z-index: 500;
            max-height: 0px;
            overflow: hidden;
            transition: .3s ease-out;

            background-color: #fff !important;
            color:#fff !important;
            border:1px solid #000 !important;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.5) !important;
            @media(max-width:1100px){
                top:inherit;
                bottom:50px;
                right: 0px;
                z-index: 500;
                width: 100%;
            }
            li{
                list-style: none;
                width: fit-content;
                padding:10px 20px;
                color:#000 !important;
                text-align: center;
                width: 100%;
                span{
                    width: fit-content;
                    white-space: nowrap;
                }
                button.btn{
                    background-color: #000 !important;
                    border:1px solid #fff !important;
                    white-space: nowrap;
                    &:hover{
                        background-color: var(--color2) !important;
                    }
                }
            }
            &.show{
                max-height: 300px;
            }
        }
    }
}
</style>