import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title:'XXI Congreso Nacional Explora' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    meta: { title:'404 - XXI Congreso Nacional Explora' }
  },
  {
    path: '/anfiteatro',
    name: 'Anfiteatro',
    component: () => import(/* webpackChunkName: "anfiteatro" */ '../views/Anfiteatro.vue'),
    meta: { title:'ANFITEATRO - XXI Congreso Nacional Explora' }
  },
  {
    path: '/feria/:r',
    name: 'Stand',
    component: () => import(/* webpackChunkName: "feria" */ '../views/Stand.vue'),
    meta: { title:'STAND - XXI Congreso Nacional Explora' }
  },
  {
    path: '/evaluaciones',
    name: 'Evaluaciones',
    component: () => import(/* webpackChunkName: "evaluaciones" */ '../views/Evaluaciones.vue'),
    meta:{ title:'EVALUACIONES - XXI Congreso Nacional Explora' }
  },
  {
    path: '/informaciones',
    name: 'Informaciones',
    component: () => import(/* webpackChunkName: "informaciones" */ '../views/Informaciones.vue'),
    meta: { title:'INFORMACIONES - XXI Congreso Nacional Explora' }
  },
  {
    path: '/buscador',
    name: 'Buscador',
    component: () => import(/* webpackChunkName: "buscador" */ '../views/buscador.vue'),
    meta: { title:'BUSCADOR - XXI Congreso Nacional Explora' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
    meta: { title:'LOGIN - XXI Congreso Nacional Explora' }
  },
  {
    path: '/pauta-evaluaciones',
    name: 'Pauta-evaluaciones',
    component: () => import(/* webpackChunkName: "pauta-eval" */ '../views/pauta-eval.vue'),
    meta: { title:'PAUTA DE EVALUACIONES - XXI Congreso Nacional Explora' }
  },
  {
    path: '/explora',
    name: 'Explora',
    component: () => import(/* webpackChunkName: "explora" */ '../views/Explora.vue'),
    meta: { title:'PROGRAMA EXPLORA - XXI Congreso Nacional Explora' }
  },
  {
    path: '/politicas',
    name: 'Politicas',
    component: () => import(/* webpackChunkName: "politicas" */ '../views/Politicas.vue'),
    meta: { title:'POLITICAS DE PRIVACIDAD - XXI Congreso Nacional Explora' }
  },
  {
    path: '/congresos-anteriores',
    name: 'Congresos-anteriores',
    component: () => import(/* webpackChunkName: "congreso-anterior" */ '../views/CongresoAnteriorView.vue'),
    meta: { title:'CONGRESOS ANTERIORES - XXI Congreso Nacional Explora' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
