<template>
    <div class="menu">
        <h4>FERIA DE PROYECTOS POR REGIÓN</h4>
        <div id="mapa">
            <ul class="rList">
                <li>
                    <router-link to="/feria/1" @mouseover="switchMap(1)" @click.prevent="cambiarRegion(1)">
                        Arica y Parinacota
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/2" @mouseover="switchMap(2)" @click.prevent="cambiarRegion(2)">
                        Tarapacá
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/3" @mouseover="switchMap(3)" @click.prevent="cambiarRegion(3)">
                        Antofagasta
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/4" @mouseover="switchMap(4)" @click.prevent="cambiarRegion(4)">
                        Atacama
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/5" @mouseover="switchMap(5)" @click.prevent="cambiarRegion(5)">
                        Coquimbo
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/6" @mouseover="switchMap(6)" @click.prevent="cambiarRegion(6)">
                        Valparaíso
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/7" @mouseover="switchMap(7)" @click.prevent="cambiarRegion(7)">
                        Metropolitana
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/8" @mouseover="switchMap(8)" @click.prevent="cambiarRegion(8)">
                        O'Higgins
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/9" @mouseover="switchMap(9)" @click.prevent="cambiarRegion(9)">
                        Maule
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/10" @mouseover="switchMap(10)" @click.prevent="cambiarRegion(10)">
                        Ñuble
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/11" @mouseover="switchMap(11)" @click.prevent="cambiarRegion(11)">
                        Biobío
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/12" @mouseover="switchMap(12)" @click.prevent="cambiarRegion(12)">
                        La Araucanía
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/13" @mouseover="switchMap(13)" @click.prevent="cambiarRegion(13)">
                        Los Ríos
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/14" @mouseover="switchMap(14)" @click.prevent="cambiarRegion(14)">
                        Los Lagos
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/15" @mouseover="switchMap(15)" @click.prevent="cambiarRegion(15)">
                        Aysén
                    </router-link>
                </li>
                <li>
                    <router-link to="/feria/16" @mouseover="switchMap(16)" @click.prevent="cambiarRegion(16)">
                        Magallanes y Antártica Chilena
                    </router-link>
                </li>
            </ul>
            <img id="imgMapa" v-show="mostrar" src="assets/img/chile/mapa-1.svg" alt="">
        </div>
    </div>
</template>
<script>
    export default {
        name: 'mnRegiones',
        props: ['mostrar'],
        data(){
            return {
                meta: { title:this.$route.meta.title, }
            }
        },
        methods:{
            switchMap(r) {
                let mapaImg = document.querySelector(`#mapa img`)
                mapaImg.src = `assets/img/chile/mapa-${r}.svg`
            },
            cambiarRegion(r){
                if(r>0 && r<17) {
                    window.location.href = `/feria/${r}`
                }
            }
        } 
    }
</script>
<style lang="scss" scoped>
.menu{
    h4{
        font-size: 1rem;
        color: var(--color3) !important;
        line-height: 1.1;
        margin-bottom: 20px;
        text-align: start;
        margin-top: 25px;
        margin-left: -20px;
    }
    #mapa{
        display: grid;
        grid-template-columns: 60% 40%;
        img#imgMapa{
            width: 100%;
        }
        ul.rList{
            position: relative;
            justify-self: flex-start;
            margin-right: 10px;
            color: #000 !important;
            li{
                list-style: none;
                text-align: right;
                font-size: 0.8em;

                a{
                    color: #000;
                    cursor: pointer;
                    
                    &:hover{
                        text-decoration-line: none !important;
                        border-bottom:2px solid var(--color2) !important;
                    }
                    &.router-link-active{
                        text-decoration-line: none !important;
                        border-bottom:2px solid var(--color2) !important;
                    }
                }
                &:nth-child(1){
                    margin-bottom:1em;
                }
                &:nth-child(2){
                    margin-bottom:1em;
                }
                &:nth-child(3){
                    margin-bottom:1em;
                }
                &:nth-child(4){
                    margin-bottom:1em;
                }
                &:nth-child(5){
                    margin-bottom:1em;
                }
                &:nth-child(6){
                    margin-bottom:1em;
                }
                &:nth-child(7){
                    margin-bottom:1em;
                }
                &:nth-child(8){
                    margin-bottom:1em;
                }
                &:nth-child(9){
                    margin-bottom:1em;
                }
                &:nth-child(10){
                    margin-bottom:1em;
                }
                &:nth-child(11){
                    margin-bottom:1em;
                }
                &:nth-child(12){
                    margin-bottom:1em;
                }
                &:nth-child(13){
                    margin-bottom:1em;
                }
                &:nth-child(14){
                    margin-bottom:1em;
                }
                &:nth-child(15){
                    margin-bottom:1em;
                }
                &:nth-child(16){
                    margin-bottom:1em;
                }
            }
        }
    }
              
    @media(max-width:1100px){
        margin-bottom:30px;
        padding:30px;
        width: 375px;
        h4{
            font-size: 1.4rem;
            line-height: 1.1;
            padding:20px;
            margin-bottom: 20px;
            text-align: center;
        }
        #mapa{
            ul.rList{
                li{
                    &:nth-child(1){
                        margin-top: 1.5rem;
                        margin-bottom:2rem;
                    }
                    &:nth-child(2){
                        margin-bottom:2rem;
                    }
                    &:nth-child(3){
                        margin-bottom:2rem;
                    }
                    &:nth-child(4){
                        margin-bottom:2rem;
                    }
                    &:nth-child(5){
                        margin-bottom:2rem;
                    }
                    &:nth-child(6){
                        margin-bottom:2rem;
                    }
                    &:nth-child(7){
                        margin-bottom:2rem;
                    }
                    &:nth-child(8){
                        margin-bottom:2rem;
                    }
                    &:nth-child(9){
                        margin-bottom:2rem;
                    }
                    &:nth-child(10){
                        margin-bottom:2rem;
                    }
                    &:nth-child(11){
                        margin-bottom:2rem;
                    }
                    &:nth-child(12){
                        margin-bottom:2rem;
                    }
                    &:nth-child(13){
                        margin-bottom:1.5rem;
                    }
                    &:nth-child(14){
                        margin-bottom:2rem;
                    }
                    &:nth-child(15){
                        margin-bottom:2rem;
                    }
                    &:nth-child(16){
                        margin-bottom:0px;
                    }
                    a{
                        color: #000;
                    }
                }
            }
        }
    }
}
</style>