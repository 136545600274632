<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view/>
 <!--  <div id="jaklcp-chat-container"></div>--> 
</template>

<style lang="scss">
</style>
