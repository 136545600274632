<template>
    <header class="headerHome">
        <img src="assets/img/home-logo-header.svg" alt="">
      
    </header>
    <mnSup/>
   
    <main id="home">
        <div class="wrap">
            <section class="menuHome">
                <img src="assets/img/home-fondo.svg" v-if="widthScreen()">
                <img src="assets/img/home-fondo-mobile.svg" v-else>
                <div class="boxMnMain">
                    <nav class="mnMain">
                        <router-link to="/anfiteatro">Anfiteatro</router-link>
                        <router-link to="/feria/1">Feria de proyectos</router-link>
                        <router-link to="/informaciones">Informaciones</router-link>
                        <router-link to="/explora">Programa Explora</router-link>
                    </nav>
                </div>
            </section>
            <aside>
                <mnRegiones mostrar="true"/>
            </aside>
        </div>
    </main>
    <footerLogo/>
</template>

<script>
// @ is an alias to /src
/* import HelloWorld from '@/components/HelloWorld.vue' */
import footerLogo from '@/components/footerLogo.vue'
import mnSup from '@/components/mnSup.vue'
import mnRegiones from '@/components/mnRegiones.vue'
export default {
  name: 'Home',
  data(){
      return{
        meta:{
            title:this.$route.meta.title,
        }
      }
  },
  components: {
    footerLogo,
    mnSup,
    mnRegiones
  },
  created(){
      document.title = 'XXI Congreso Nacional Explora'
  },
  methods:{
    /*switchMap(r){
      let mapaImg = document.querySelector(`#mapa img`);
      //console.log(mapaImg);

      mapaImg.src = `assets/img/chile/mapa-${r}.svg`;
    }, */
    widthScreen(){
        if(window.innerWidth>1100){
            return true
        }else{
            return false
        }
    }
  },
  computed:{
      
      
  }
}
</script>

<style lang="scss" scoped>

header.headerHome{
    position: absolute;
    top:0px;
    left: 20px;
    z-index: 10;
    width: 250px;
    height: auto;

    background-color: transparent;
    @media(max-width:1100px){
        width: 220px;
    }
}
main#home{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 100%;
    @media(max-width:700px){
        display:block;
    }
    .wrap{
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr 200px;
        margin-top: 5%;
        margin-left: 15vw;

        section.menuHome{
            position: relative;
            width: 1000px;
            height: 700px;
            img{
                width: 100%;
                height: auto;
            }
            .boxMnMain{
                position: absolute;
                top:204px;
                left:278px;
                z-index: 10;
                width:290px;
                nav.mnMain{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        position: relative;
                        top: 17px;
                        min-width: 100px;
                        max-width: 300px;
                        width: fit-content;
                        height: 65px;
                        background-color: var(--color2);
                        border-radius: 80px;
                        border-style: solid;
                        border-color: black;
                        margin-bottom:29px;
                        
                        color:var(--color1);
                        text-align: center;
                        text-transform: uppercase;
                        text-decoration: none;
                        padding:27px;

                        //transition: all .3s ease-out;
                        &:nth-child(1){
                            right:-49px;
                            margin-left:auto;
                        }
                        &:nth-child(2){
                            left:40px;
                        }
                        &:nth-child(3){
                            right:-5px;
                            margin-left:auto;
                        }
                        &:nth-child(4){
                            left: 27px;
                        }

                        &:hover{
                            max-width: 250px;
                            background-color: #000;
                            span{
                                width: 100%;
                            }
                        }
                    }
                }
                
            }
        }

        @media(max-width:1100px){
            grid-template-columns: 1fr;
            padding:0px;
            padding-top:135px;
            margin-left:auto;
            section.menuHome{
                width: 100%;
                height: auto;
                .boxMnMain{
                    top:213px;
                    left:99px;
                    width:179px;
                    nav.mnMain{
                        a{
                            top:0px;
                            min-width: 55px;
                            max-width: 300px;
                            width:100%;
                            height: 55px;
                            margin-bottom:23px;
                            font-size: .8em;
                            padding:10px;
                            &:nth-child(1){
                                right:5px;
                                margin-left:auto;
                            }
                            &:nth-child(2){
                                left:0px;
                            }
                            &:nth-child(3){
                                right:3px;
                                margin-left:auto;
                            }
                            &:nth-child(4){
                                left:0px;
                                top:2px;
                            }
        
                            & span{
                                display: block;
                                width:100%;
                                overflow: hidden;
                                transition: all .1s ease-out;
                                white-space: nowrap;
                            }
                            &:hover{
                                max-width: 250px;
                                /* background-color:var(--color2); */
                                span{
                                    width: 100%;
                                }
                            }
                        }
                }
                }
            }

        }
    }
}
</style>
