import { createStore } from 'vuex'

export default createStore({
  state: {
    showModal:false,
    apiURLQA:'https://test.congresonacionalexplora.cl',
    apiURL:'https://congresonacionalexplora.cl',
    loged:false,
    fhoy:'',
    session:{}
  },
  mutations: {
    showModal(state){
      state.showModal = true;
    },
    closeModal(state){
      state.showModal = false;
    },
    async logVerify(state){
      state.session = JSON.parse(localStorage.getItem("session"))
      if(state.session!='' && state.session!=null){
        let url = `${state.apiURL}/api/?clase=MasterData&metodo=verifySession&parametros={"idsession":"${state.session.sessionid}"}`
        try{
          let rs = await fetch(url);
          let data = await rs.json();

          if(!data.salida){
            state.loged = false;
            window.location.href = '/login';
          }else{
            state.loged = true;
            return
          }
        }catch(e){
          console.log(e);
          localStorage.removeItem("session")
          state.session = ''
          state.loged = false;
          window.location.href = '/login';
          //return
        }
      }else{
        window.location.href = '/login';
      }

    },
    async logOut(state){
      let url = `${state.apiURL}/api/?clase=MasterData&metodo=logout&parametros={"idsession":"${state.session.sessionid}"}`
      try{
        let rs = await fetch(url);
        let data = await rs.json();

        if(data.salida){
          localStorage.removeItem("session")
          state.session = ''
          state.loged = false;

          window.location.href = '/login';
        }else{
          console.error(data.error)
          return
        }
      }catch(e){
        console.log(e);
        return
      }
    },
    fechaHoy(state){
      const meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
      const f=new Date();
      const hoy = f.getDate() + " de " + meses[f.getMonth()] + " de " + f.getFullYear();
      state.fHoy = hoy
    }
  },
  actions: {
  },
  modules: {
  }
})
